.home-container{
  margin:  15vh 5% 15vh 5%;
}

.content_container{
  margin:  0% 5% 5% 5%;
  padding: 15vh 5% 15vh 5%;
  border: 1px solid #6f6f6f;
  border-radius: 20px;
}
.above-msg{
    color:#623091;
    font-weight: 600;
}

.en-photo{
width: 50%;
}

.ar-photo{
    width: 50%;
}
.logo{
    width: 50%;
}
.icon{
    width: 20%;
}