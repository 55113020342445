.footer_table_content {
  text-align: center;
  color: black;
  font-size: small;
  font-weight: 400;
  direction: rtl;
  white-space: pre-line;
}
.footer_table_contact_info {
  font-weight: 500;
  font-size: x-small;
  color: #6f6f6f;
}
a:link {
  color: #6f6f6f;
}

a:visited {
  color: #6f6f6f;
}

a:hover {
  color: #623091;
}
