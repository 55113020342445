.table_content {
 border-color: #623091;
  border-width: 1px;
  border-style: solid;
  border-radius: 0 0 10px 10px;
  padding: 10px;
  max-width: 900px;
  margin: 0% auto;
}

.diagnosis_table_row {
  flex-direction: row;
  display: flex;
  margin-bottom: 5px;
}

.diagnosis_table_title {
  background-color: #bdbdbd72;
  padding: 10px;
  border-radius: 10px;
  width: 35%;
  font-size: small;
  color: #000000;
  font-weight: 600;
}

.diagnosis_table_content {
  padding: 10px;
  border-radius: 10px;
  color: black;
  font-size: small;
}
