.drug-container {
  margin: 0 0 10px 0;
  color: black;
  font-size: small;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1%;
}
.general {
  display: grid;
  grid-auto-flow: row;
  border: 1px solid #00000073;
}
.drug-info {
  display: grid;
  grid-auto-flow: column;
}
.general.drug-name {
  border-bottom-width: 0px;
  border-radius: 10px 10px 0 0;
  background-color: #bdbdbd72;
  font-size:larger;
}
.general.other-instraction {
  border-top-width: 0px;
  border-radius: 0 0 10px 10px;
}
strong {
  padding: 1%;
}
span {
  padding: 1%;
}
