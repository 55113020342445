.table_content {
  border-color: #623091;
  border-width: 1px;
  border-style: solid;
  border-radius: 0 0 10px 10px;
  max-width: 900px;
  padding: 10px;
  margin: 0% auto;
  background-color: white;
}

.info_table_row {
  padding: 0.5%;
  flex-direction: row;
  display: flex;
  margin-bottom: 5px;
  justify-content: center;
  border-bottom: 1px solid #6f6f6f73;
}
.info_table_row.before-last {
  border-bottom-width: 0px;
}

.info_table_row.last {
  border-top: 1px solid #6f6f6f73;
  border-bottom-width: 0px;
}

.info_table_title {
  color: black;
  padding: 5px;
  width: 50%;
  font-size: small;
  justify-content: center;
  border-color: #6f6f6f;
}

.info_table_content {
  color: black;
  padding: 5px;
  font-size: 15px;
  justify-content: center;
  align-self: center;
  width: 50%;
}
