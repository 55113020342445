.failure{
    width: 100%;
    height: 100%;
    background-color: white;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.logo_container{
    width: 100%; 
    align-content: start;
    align-items: start;
    margin: 20px 0 20px 0;
}

.logo{
    width: 100px;
}

.failure_message_container{
    margin: 20px; 
    padding: 10px;
    border: 2px solid #6f6f6f73;
    border-radius: 10px;
}

.failure_message_text{
    color: #623091;
    white-space: pre-line;

}