.msg {
  color: black;
  font-size: small;
  font-weight: bold;
  text-align: center;
  margin: 0% auto -2% auto;
}
.success {
  width: 100%;
  background-image: linear-gradient(
    0deg,
    rgba(98, 48, 145, 0.032) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
.success__content {
  margin: 0% 2%;
  background-image: linear-gradient(
    0deg,
    rgba(98, 48, 145, 0.032) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.logo_container {
  width: 100%;
  align-content: start;
  align-items: start;
}

.logo {
  width: 20%;
  max-width: 200px;
}

.table_header {
  background-color: #623091;
  border-radius: 10px 10px 0 0;
  max-width: 902px;
  padding: 10px;
  color: white;
  font-size: large;
  font-weight: 700;
  text-align: start;
  margin: 2% auto 0% auto;
}

.table_content {
  max-width: 900px;
  border-color: #623091;
  border-width: 1px;
  border-style: solid;
  border-radius: 0 0 10px 10px;
  margin: 0% auto;
  padding: 10px;
}

.footer_table_content {
  text-align: center;
  color: black;
  font-size: small;
  font-weight: 400;
  direction: rtl;
  white-space: pre-line;
  padding: 10px 5px 10px 5px;
}
